/*fix title*/
ul.menu-unit{
	background: linear-gradient(to bottom, #fff, #ECF0F1);
	@include width100pr;
	@include clearfix;
	padding: 0;
	list-style: none;
	line-height: 0;
	margin-bottom: 15px;
	border-bottom: 1px solid #CFCFCF;
	font-size: 0;
	
	li{
		float: left;
		position: relative;
		z-index: 100;
		padding: 0;
		margin: 0;

		background: url(../img/typeNavLine.jpg) no-repeat right;
		
		&:first-child{
			background: linear-gradient(to top, #e0e4e5, #f9fafc);
		}
		
		a{
			display: block;
			padding: 25px;
			text-decoration: none;
			font-weight: 400;
			font-size: 14px;
			color: #696969;

			&:hover{
			color: #d6000f;
			}

			&.active {
				padding-bottom: 21px;
				border-bottom: #d6000f solid 4px;
				color: #d6000f;
			}
			
			@include respond-to(tablets){
				padding: 25px 9px;
			}	
			@include respond-to(small-tablets){
				padding: 25px 6px;
				font-size: 11px;
			}	
			@include respond-to(wide-handhelds){
				width: 100%;
				left: 0;
			}	
			@include respond-to(handhelds){
				width: 100%;
				left: 0;
			}
		}
	}
	
	span{
		float: left;
		display: block;
		width: 1px;
		height: 40px;
		margin-top: 5px;
		background: linear-gradient(to top, #fff, #ECF0F1);
		
		@include respond-to(small-tablets){
			margin-top: 0px;
		}
	}
	
	@include respond-to(tablets){
		width: 100%;
		left: 0;
	}	
	@include respond-to(small-tablets){
		width: 100%;
		left: 0;
	}	
	@include respond-to(wide-handhelds){
		width: 100%;
		left: 0;
		display: none;
	}	
	@include respond-to(handhelds){
		width: 100%;
		left: 0;
		display: none;
	}
}

.menu-unit li:hover > a{
	color: #000;
	text-decoration: none;
}


ul.view-list-block{
	font-size: 0;
	@include clearfix;
	margin: 0;
	padding: 0;
	
	li{
		position: relative;
		@include clearfix;
		padding: 10px 0;
		margin-bottom: 15px;

		.left-img-cell{
			float: left;
			width: calc(60% - 20px);
			margin: 10px;
			padding: 4px;
			border: 1px solid #E5E5E5;
			box-sizing: border-box;
			
			img{
				width: 100%;
				padding: 0;
				margin: 0;
			}
			
			@include respond-to(tablets){
				width: calc(40% - 20px);
			}
			@include respond-to(small-tablets){
				float: none;
				width: 90%;
				margin: 0 auto;
			}
			@include respond-to(wide-handhelds){
				float: none;
				width: 90%;
				margin: 0 auto;
			}
			@include respond-to(handhelds){
				float: none;
				width: 90%;
				margin: 0 auto;
			}
		}
		.text-block-left{
			float: left;
			width: 40%;
			padding: 0 30px;
			box-sizing: border-box;
		
			h1,h2,h3,h4{
				font-size: 40px;
				margin-bottom: 10px;
				line-height: 1;
				
				@include respond-to(tablets){
					font-size: 30px;
				}
				@include respond-to(small-tablets){
					font-size: 20px;
					text-align: center;
				}
				@include respond-to(wide-handhelds){
					font-size: 20px;
					text-align: center;
				}
				@include respond-to(handhelds){
					font-size: 20px;
					text-align: center;
				}
			}
			p{
				font-size: 13px;
				line-height: 160%;
				clear: both;
				text-align: left;
				margin-bottom: 5px;
				
				@include respond-to(tablets){
					text-align: justify;
				}
				@include respond-to(small-tablets){
					text-align: justify;
				}
				@include respond-to(wide-handhelds){
					text-align: justify;
				}
				@include respond-to(handhelds){
					text-align: justify;
				}
			}
			
			@include respond-to(tablets){
				width: calc(60% - 20px);
				padding: 0 10px;
			}
			@include respond-to(small-tablets){
				width: 100%;
				padding: 0 10px;
			}
			@include respond-to(wide-handhelds){
				width: 100%;
				padding: 0 10px;
			}
			@include respond-to(handhelds){
				width: 100%;
				padding: 0 5px;
			}
		}
		
		.right-img-cell{
			display: block;
			float: right;
			width: calc(60% - 20px);
			margin: 10px;
			padding: 4px;
			line-height: 100%;
			border: 1px solid #E5E5E5;
			box-sizing: border-box;
			overflow: hidden;
			
			img{
				width: 100%;
				padding: 0;
				margin: 0;
			}
			
			@include respond-to(tablets){
				width: calc(40% - 20px);
			}
			@include respond-to(small-tablets){
				float: none;
				width: 90%;
				margin: 0 auto;
			}
			@include respond-to(wide-handhelds){
				float: none;
				width: 90%;
				margin: 0 auto;
			}
			@include respond-to(handhelds){
				float: none;
				width: 90%;
				margin: 0 auto;
			}
		}
		.text-block-right{
			float: left;
			width: 40%;
			padding: 0 30px;
			box-sizing: border-box;
			
			h1,h2,h3,h4{
				font-size: 40px;
				margin-bottom: 10px;
				//background-color:red;
				line-height: 1;
				
				@include respond-to(tablets){
					font-size: 30px;
				}
				@include respond-to(small-tablets){
					font-size: 20px;
					text-align: center;
				}
				@include respond-to(wide-handhelds){
					font-size: 20px;
					text-align: center;
				}
				@include respond-to(handhelds){
					font-size: 20px;
					text-align: center;
				}
			}
			p{
				font-size: 13px;
				line-height: 160%;
				clear: both;
				text-align: left;
				margin-bottom: 5px;
				
				@include respond-to(tablets){
					text-align: justify;
				}
				@include respond-to(small-tablets){
					text-align: justify;
				}
				@include respond-to(wide-handhelds){
					text-align: justify;
				}
				@include respond-to(handhelds){
					text-align: justify;
				}
			}
			
			@include respond-to(tablets){
				width: calc(60% - 20px);
				padding: 0 10px;
			}
			@include respond-to(small-tablets){
				width: 100%;
				padding: 0 10px;
			}
			@include respond-to(wide-handhelds){
				width: 100%;
				padding: 0 10px;
			}
			@include respond-to(handhelds){
				width: 100%;
				padding: 0 5px;
			}
		}
	}
}

.kredit-block{
	text-align: center;
	padding: 10px 0;
	
	@include respond-to(handhelds){	
				margin-top:10px;
			}
	
	a{
		font-size: 22px;
		font-weight: 600;
		margin: 0 20px;
		color: #EE0000;
	}
	span{
		font-size: 22px;
		font-weight: 600;
		color: #EE0000;
	}
	a:hover{
		margin: 0 5px;
		text-decoration: none;
	}
}

.view-list-block:after{
	content: '';
	display: table;
	clear: both;
}


//extra
ul.card-inn{
	@include clearfix;
	font-size: 0;
	padding: 10px 0 20px 0;
	margin: 0;

	li{
		display: inline-block;
		vertical-align:top;
		margin: 10px;
		@include clearfix;
		position: relative;
		
		.wrapper-image{
			border: 1px solid #CFCFCF;
			margin-bottom: 5px;
			
			img{
				width: calc(100% - 8px);
				margin: 4px;
				padding: 0;
			}
		}
		p{
			font-size: 12px;
			
			span{
				color: red;
			}
		}
		
		@include respond-to(display-big){
			width:calc(33.33% - 22px);
		}
		@include respond-to(display){
			width:calc(33.33% - 22px);
		}
		@include respond-to(tablets){
			width:calc(33.33% - 22px);
		}
		@include respond-to(small-tablets ){
			width:calc(33.33% - 12px);
			margin: 5px;
		}
		@include respond-to(wide-handhelds){
			width:calc(50% - 12px);
			margin: 5px;
		}
		@include respond-to(handhelds){
			width:calc(100% - 12px);
			margin: 5px;
		}
	}
	li:hover .text-card a{
		color: #fff;
		border: none;
	}
}


.wrapper_front{
	width: calc(100% - 42px);
	margin: 20px;
	position: relative;
	
	.dwnld{
		position: absolute;
		top: -17px;
		right:5px;
		//width: 100px;

		img{
			display: inline-block;
			width: 42px;
			height: 42px;
			margin: 0;
			vertical-align: middle;
		}
		a{
			font-size: 16px;
			line-height: 100%;
			color: #000;
		}
		b {font-weight:normal;}
		
			@include respond-to(small-tablets){
			
				top: -10px;
			
					img{
						width: 36px;
						height: 36px;
						}
			
					a{
						font-size: 14px;
					}
			
			}
			@include respond-to(wide-handhelds){
	
				top: -10px;
			
				img{
					width: 36px;
					height: 36px;
					}
			
				a{
					font-size: 14px;
				}

			}
			@include respond-to(handhelds){
	
				top: -10px;
			
				img{
					width: 36px;
					height: 36px;
					}
			
				a {
					font-size: 14px;
					}
					
					b {display:none;}

			}
		
		
		
	}
	
	.tabs_front{
		height: 25px;
		//line-height: 25px;
		margin: 0 0 3px;
		list-style: none;

		li{
			display: inline-block;
			margin-right: 1px;
			cursor: pointer;
			
			a{
				display: block;
				padding: 5px 15px 3px 15px;
				color: #232323;
				text-decoration: none;
				cursor: pointer;
				background: linear-gradient(to bottom, #fff, #D0D0D0);
				border: 1px solid #EFEFEF;
				border-bottom: 1px solid #F9F9F9;
				
				&:hover{
					color: #000;
					padding: 5px 15px 3px 15px;
					text-decoration: none;
				}
			}
		}
		.tab_active_front{
			a{
				color: #E43F4A;
				background: #fff;
				padding: 5px 15px 4px 15px;
				border: 1px solid #DDD;
				border-bottom: 1px solid #fff;
				
				&:hover{
					background: #fff;
					padding: 5px 15px;
					text-decoration: none;
					color: #E43F4A;
				}
			}
		}
	}
	
	@include respond-to(small-tablets){
		width: calc(100% - 22px);
		margin: 10px;
	}
	@include respond-to(wide-handhelds){
		width: calc(100% - 22px);
		margin: 10px;
	}
	@include respond-to(handhelds){
		width: calc(100% - 12px);
		margin: 10px 5px;
	}
}





.wrapper_front div#t2,
.wrapper_front div#t3{
	display: none;
}
.wrapper_front div#t1,
.wrapper_front div#t2,
.wrapper_front div#t3{
	border: 1px solid #DDD;
	padding: 10px 5px;
}



/*tables*/
.teh table {
	margin: 0;
}
.teh table td,  table th{
	padding: 4px;
	border: 1px solid #DDD;

}
.teh table {
	font-size: 12px;
}
.marker{
	text-align: center;
}
.table{
	table{
		width: 100%;
		
		@include respond-to(display){
			width: 900px;
		}
		@include respond-to(tablets){
			width: 850px;
		}
		@include respond-to(small-tablets){
			width: 750px;
		}
		@include respond-to(handhelds){
			width: 775px;
		}
		@include respond-to(wide-handhelds){
			width: 700px;
		}
	}
	
	@include respond-to(display){
		overflow-y: scroll;
		max-width: 900px;
	}
	@include respond-to(tablets){
		overflow-y: scroll;
		max-width: 850px;
	}
	@include respond-to(small-tablets){
		overflow-y: scroll;
		max-width: 750px;
	}
	@include respond-to(handhelds){
		overflow-y: scroll;
		max-width: 775px;
	}
	@include respond-to(wide-handhelds){
		overflow-y: scroll;
		max-width: 700px;
	}
}





//fixed mobile-menu
.wrapper-view-menu{
	display: none;
	box-shadow: 0 3px 11px #aaa;
	background: linear-gradient(to top, #e0e4e5, #f9fafc);
	
	@include respond-to(wide-handhelds){
		display: block;
		width: 100%;
	}
	@include respond-to(handhelds){
		display: block;
		width: 100%;
	}
}


#touch-view-menu {cursor: pointer;}

.wrapper-mobile-view-menu{
	@include clearfix;
	
	p{
		float: left;
		padding: 16px 0;
		font-weight: 600;
		color: #c3010e;
	}
	
	.mobile-view-menu{
		display: block;
		float: left;
		padding: 5px 3px;
		margin: 9px 10px;
		
	
		.icon_bar{
			width: 15px;
			height: 1px;
			margin: 3px;
			border-radius: 4px;
			background-color: #c3010e;
			
			@include respond-to(wide-handhelds){
				width: 25px;
				height: 5px;
				margin: 2px;
			}
			@include respond-to(handhelds){
				width: 25px;
				height: 5px;
				margin: 2px;
			}
		}
	}
}
ul.view-menu-mobile{
	display: none;
	width: 100%;
	@include clearfix;
	margin: 0;
	
	li{
		position: relative;
		float: none;
		width: 100%;
		margin: 0;
		background: linear-gradient(to top, #eff2f3, #f5f5f5);
		z-index: 100;
		border-bottom: 1px solid #fff;
		list-style: none;
	}
	li a{
		display: block;
		padding: 8px 10px;
		font-size: 16px;
		text-decoration: none;
		color: #000;
	}
	li:last-child{
		border: none;
	}
	li a:hover{
		color: #d6000f;
		background-color: #E8E8E8;
		text-decoration: none;
	}
	.activ-top a{
		color: #d6000f;
		text-shadow: none;
	}
}



ul.colors{
	//background-color: red;
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 10px 20px;
	
	li{
		display: inline-block;
		margin: 5px;
		padding: 1px;
		box-shadow: 0 3px 11px #000;
		
		img{
			width: 100%;
			padding: 0;
			margin: 0;
		}
		
		@include respond-to(handhelds){
			margin: 2px;
		}
	}
}



.teh table {width:100%;}
.teh table tr{height:30px; background:#fff;}
.teh table tr td {padding:5px 5px 0px 10px;}
.teh table.tablePrice tr td+td, .teh table tr td+td+td, .teh table tr td+td+td+td, .teh table tr td+td+td+td+td {text-align:center; font-size:16px; font-weight:bold;}
.teh table.tablePrice tr th+th, .teh table tr th+th+th, .teh table tr th+th+th+th, .teh table tr th+th+th+th+th {text-align:center;}
.teh table tr.odd {background:#f6f8fa;}
.teh table th { padding:5px 5px 0px 10px;}
.teh table th.red1 {color:#fff; background:#d90012}
.teh table th.red2, .teh table th.red3 {color:#d90012;}
.teh table tr td.akcia {font-size:16px; font-weight:bold; color:#D6000F;}
.teh table tr td.akcia span {font-size:14px; font-weight:normal; color:#40464D;}


.teh table.tableService tr td+td, .teh table.tableService tr td+td+td, .teh table.tableService tr td+td+td+td, .teh table.tableService tr td+td+td+td+td {text-align:center; font-size:11px; font-weight:bold;}

.teh table.tablePrice tr.fon-bord td, .teh table.tablePrice tr.fon-bord th {background:#c0504d; color:#fff; border:1px solid #fff; font-size:14px; font-weight:bold; padding-bottom:5px;}

table.e2g tr.odd {background:none;}

.teh table tr td.option {height:40px; font-size:16px; padding:10px 0px 0px 10px; text-align:left; font-weight:bold;}

.tableTth {width:100%}
.tableTth caption {height:40px; font-size:16px; padding:10px 0px 0px 10px; text-align:left; font-weight:bold;}
.tableTth  td {width:40%}

table.regionList {margin:0px 0px 40px 0px; width:100%; border: 0px; font-size: 14px;}
.regionList caption{height:40px; font-size:16px; padding:10px 0px 0px 10px; text-align:left;  font-weight:bold;}
.regionList a {color:#c3010e;}

table.regionList td+td+td {width:15%; white-space: nowrap;}
table.regionList td+td {width:45%; white-space: nowrap;}
table.regionList th {border:0px;}
table.regionList td {border:0px;}


img[usemap], object[usemap] {
  color: #c3010e;
}

.region-short {max-width: 100%; overflow-y: scroll;}


			@include respond-to(wide-handhelds){
				.map-img-show {display: none;}
			}
			@include respond-to(handhelds){
				.map-img-show {display: none;}
			}