//top-menu
.wrapper-menu{
	@include clearfix;
	padding: 1px 0;
	background-color: #F0EEEF;
	
 	@include respond-to(handhelds){
		padding:0 0 10px 0;
	}
}
.top-menu{

	margin-top:20px; 

	width:calc(100%-220px);
	float:right;

	background-color: #F3F3F3;
	
	background: url('../img/navBg.jpg');
	background-size: contain;
	@include clearfix;

	@include respond-to(tablets){
		width:calc(100%-220px); 
		padding:0 5px;
	}	
	@include respond-to(small-tablets){
		width:calc(100%-220px); 
		padding:0 5px;
	}	
	@include respond-to(wide-handhelds){
		width:calc(100%-220px); 
		padding:0 5px;
	}	
 	@include respond-to(handhelds){
 			margin-top:10px; 
		width:calc(100%-220px); 
		padding:0 5px;
		background: url('');
	}
}
ul.menu{
 	display: block;
	float: right;
	font-size: 0;
	
 	@include respond-to(handhelds){
		display: none;
	}
}
ul.menu > li{
	float: left;
	list-style: none;
	position: relative;
	padding: 0 15px;
	background: url(../img/navLine.png) no-repeat right center;
	
	@include respond-to(tablets){
		padding: 0 10px;
	}
	@include respond-to(small-tablets){
		padding: 0 3px;
	}
	@include respond-to(wide-handhelds){
		padding: 0 5px;
	}
}
ul.menu .last-child-menu, #sub-hide{
	background: url('');
}
ul.menu > li:hover .sub, ul.menu > li:hover .sub-menu{
	display: block;
	
	@include respond-to(small-tablets){
		display: none;
	}	
	@include respond-to(wide-handhelds){
		display: none;
	}	
	@include respond-to(handhelds){
		display: none;
	}
}
ul.menu > li > a:hover{
	color: #C3010E;
	border-bottom: 3px solid #C3010E;
}
ul.menu .active > a{
	border-bottom: 3px solid #C3010E;
}
ul.menu > li > a{
	display: block;
	padding: 7px 5px;
	font-size: 13px;
	color: #4F4F4F;
	border-bottom: 3px solid transparent;
}
//submenu cars
ul.sub{
	padding: 0;
	background-color: #F6F6F6;
	position: absolute;
	display: none;
	z-index: 9999;
	box-shadow: rgba(156,156,156,0.7) 0px 2px 7px;
}
//submenu
ul.sub-menu > li{
	padding: 0;
}
ul.sub-menu{
	background-color: #F6F6F6;
	box-shadow: rgba(156,156,156,0.7) 0px 2px 7px;
	position: absolute;
	z-index: 9999;
	display: none;
	right: 0;
}
ul.sub-menu > li > a{
	color: #000;
	padding: 10px 20px;
	font-size: 12px;
	background: linear-gradient(to top, #E8E8E8, #fff);
	margin: 0;
	display: block;
	
	&:hover{
		color: #C3010E;
	}
}


//hide menu (3 dots)
#sub-hide{
	display: none;
	padding: 0;
	
	a{
		position: relative;
	}
	&:hover .hide_menu{
		display: block;
	}
	
	@include respond-to(wide-handhelds){
		display: block;
	}
	@include respond-to(handhelds){
		display: block;
	}
}
#sub1,#sub2,#sub3,#sub4{
	display: block;
	
	@include respond-to(wide-handhelds){
		display: none;
	}
	@include respond-to(handhelds){
		display: none;
	}
}
#sub-hide > a{
	font-weight: 800;
	padding: 2px;
}
#sub-hide > a:hover{
	border-bottom: 3px solid transparent;
}
.hide_menu{
	display: none;
	position: absolute;
	right: 0;
	padding: 5px 0;
	z-index: 9999;
	background-color: #F0EEEF;
	box-shadow: rgba(156,156,156,0.7) 0px 2px 7px;
	
	li{
		a{
			font-size: 13px;
			padding: 6px 15px;
			display: block;
			font-weight: 400;
			color: #000;
			
			&:hover{
				color: #C3010E;
			}
		}
		
		&:hover{
			background-color: #F3F3F3;
		}
	}
}



//submenu cars
.wrapper_car{
	width: 555px;
	@include clearfix;

	@include respond-to(tablets){
		width: 520px;
	}
}
//left part submenu with cars
.wrapper_car ul.tabs{
	line-height: 25px;
	width: 165px;
	float: left;
	
	@include respond-to(tablets){
		width: 155px;
	}
}
 .wrapper_car > div{
	float: left;
}
.tabs li{
	cursor: pointer;
	position: relative;
	background: linear-gradient(to top, #E8E8E8, #fff);
	z-index: 10;
	margin: 0;
	line-height: 1;
}
.tabs li i{
	position: absolute;
	right: 20px;
	top: 35%;
	color:red;
	font-size: 10px;
}
 .tabs > li > span{
	position: absolute;
	top: 15px;
	right: -25px;
	display: none;
	
	i{
		color: #E8E8E8;
		font-size: 15px;
	}
}
.tabs > .tab_active > span{
	display: block;
}
.tabs li a {
	color: #aaa;
	display: block;
	cursor: pointer;
	font-size: 13px;
	padding: 15px 25px;
	margin: 0;
}
.tabs li.tab_active a{
	color: #C3010E;
}
div#t2,
div#t3,
div#t4,
div#t5{
	display: none;
	@include clearfix;
}
//right part submenu with cars
.wrapper_car div#t1,
.wrapper_car div#t2,
.wrapper_car div#t3,
.wrapper_car div#t4,
.wrapper_car div#t5{
	background: #F3F3F3;
	padding: 20px;
	width:350px;
	@include clearfix;
	box-shadow: rgba(221,221,221,0.7) 0px 0px 7px;
	
	@include respond-to(tablets){
		width: 325px;
	}
}
//card of sub-menu
.card-grid{
	@include clearfix;
	font-size: 0;
	margin: 0;

	li{
		display: inline-block;
		vertical-align:top;
		margin: 1px;
		@include clearfix;
		position: relative;
		border: 1px solid #DEDEDE;
		background: url('../img/card.png');
		background-size: cover;
		box-shadow: rgba(221,221,221,1) 2px 2px 10px;
		padding: 0;

		a{
			padding: 5px;
			display: block;
			
			img{
				width: calc(100% - 10px);
			}
		}
		
		.title{
			padding: 5px;
			
			h2{
				color: #CD2626;
				font-size: 16px;
			}
			a{
				font-size: 12px;
				padding: 5px;
				color: #aaa;
				
				&:hover{
					color: #4F4F4F;
				}
				
				i{
					color: #FF0000;
					font-size: 10px;
				}
			}
		}
		
		
		@include respond-to(display-big){
			width:calc(50% - 4px);
		}
		@include respond-to(display){
			width:calc(50% - 4px);
		}
		@include respond-to(tablets){
			width:calc(50% - 4px);
		}
	}
	li:hover .text-card a{
		color: #fff;
		border: none;
	}
}



//fixed mobile-menu
.wrapper-mobile-menu, .menu-mobile{
	display: none;
}
.wrapper-mobile-menu{
	//background-color:red;
	@include clearfix;
	
	p{
		float: right;
		padding: 16px 0;
		font-weight: 600;
		color: #c3010e;
	}
	
	.mobile-menu{
		display: block;
		float: right;
		padding: 5px 3px;
		margin: 9px 10px;
		cursor: pointer;
	
		.icon_bar{
			width: 15px;
			height: 1px;
			margin: 3px;
			border-radius: 4px;
			background-color: #c3010e;
			
			@include respond-to(wide-handhelds){
				width: 25px;
				height: 5px;
				margin: 2px;
			}
			@include respond-to(handhelds){
				width: 25px;
				height: 5px;
				margin: 2px;
			}
		}
	}

	@include respond-to(handhelds){
		display: block;
	}
}
.menu-mobile{
	display: none;
	width: 100%;
	@include clearfix;

	li{
		position: relative;
		float: none;
		width: 100%;
		margin: 0;
		background-color:#E8E8E8;
		z-index: 100;
		border-bottom: 1px solid #fff;
	}
	li a{
		display: block;
		padding: 8px 10px;
		font-size: 16px;
		text-decoration: none;
		color: #000;
	}
	li:last-child{
		border: none;
	}
	li a:hover{
		color: #aaa;
		background-color: #E8E8E8;
		transition: 0.5s;
		text-shadow: 1px 1.2px 0 #aaa;
	}
	.activ-top a{
		color: #D81F23;
		text-shadow: none;
	}
}



