.wrapper-header{
	background-color: #F0EEEF;
	@include clearfix;
	box-shadow: 0 29px 11px #000;
	
	.header{
		@include width1000;
		background-color: #F0EEEF;

		.logo{
			width: 220px;
			float: left;
			//background-color:green;
			
			img{
				max-width: 100%;
			}


		}
		
/* 		.language_search{
			width: 280px;
			float: right;
			padding: 13px 0;

			.language_menu{
				@include respond-to(handhelds){
					display: none;
				}
				@include respond-to(wide-handhelds){
					display: none;
				}
				@include respond-to(small-tablets){
					display: none;
				}
			}
			
			.search{
				width: 150px;
				position: relative;
				float: right;
				
				.wrap_search{
					border: 1px solid #B5B5B5;
					background-color: #fff;
					border-radius: 3px;
					-wedkit-border-radius: 3px;
					-moz-border-radius: 3px;
					-khtml-border-radius: 3px;
				}
				.search_input{
					padding: 3px 0px 3px 5px;
					border: none;
					background-color: #fff;
					border-radius: 3px;
					-wedkit-border-radius: 3px;
					-moz-border-radius: 3px;
					-khtml-border-radius: 3px;
					outline: none;

					@include respond-to(handhelds){
						width: 90%;
						padding: 7px 0px 7px 5px;
					}
				}
				.search_input::-moz-placeholder{
					color: #CFCFCF;
				}
				.search_input::-webkit-input-placeholder{
					color: #CFCFCF;
				}
				.search_input:-moz-placeholder{
					color: #CFCFCF;
				}
				.search_input:-ms-input-placeholder{
					color: #CFCFCF;
				}
				.search_input:focus::-webkit-input-placeholder{
					color: transparent;
				}
				.search_input:focus::-moz-placeholder{
					color: transparent;
				}
				.search_input:focus:-moz-placeholder{
					color: transparent;
				}
				.search_input:focus:-ms-input-placeholder{
					color: transparent;
				}
				.btn_search{
					position: absolute;
					width: 20px;
					height: 20px;
					top: 2px;
					right: 2px;
					background: url(../img/search.png) no-repeat;
					background-size: cover;
					cursor: pointer;

					@include respond-to(handhelds){
						width: 30px;
						height: 30px;
						top: 1px;
						right: 5px;
					}
				}
				
				@include respond-to(handhelds){
					width: 80%;
					margin: 0 auto;
					float: none;
				}
			}
			
			@include respond-to(handhelds){
				width: 100%;
				float: none;
			}
			@include respond-to(wide-handhelds){
				max-width: 160px;
				width:calc(100% - 20px); 
				margin:10px;
			}
			@include respond-to(small-tablets){
				max-width: 160px;
				width:calc(100% - 20px); 
				margin:10px;
			}
			@include respond-to(tablets){
				margin:10px;
			}
		} */
		
		@include respond-to(handhelds){
			width: 100%;
		}
		@include respond-to(wide-handhelds){
			width: 100%;
		}
		@include respond-to(small-tablets){
			width: 100%;
		}
		@include respond-to(tablets){
			width: 100%;
		}
	}
}


ul.language_menu > li{
	float: left;
	position: relative;
	border: 1px solid #F3F3F3;
	
	&:hover{
		border-left: 1px solid #aaa;
		border-right: 1px solid #aaa;
		border-top: 1px solid #aaa;
		background-color: white;
	}
	
	a{
		padding: 4px 10px;
		display: block;
		width: 100px;
		color: #000;
		text-align: center;
		
		&:hover{
			background-color: #fff;
		}
		
		img{
			width: 17px;
			height: 17px;
			float: left;
			position: absolute;
			left: 5px;
			top: 3px;
		}
		span{
			float: right;
			font-size: 10px;
			line-height: 18px;
			color: #aaa;
			position: absolute;
			right: 5px;
			top: 3px;
		}
	}
	
	.language_submenu{
		display: none;
		position: absolute;
		width: 120px;
		top: 22px;
		left: -1px;
		background-color: white;
		border-left: 1px solid #aaa;
		border-right: 1px solid #aaa;
		border-bottom: 1px solid #aaa;
		z-index: 99;
		
		li{
			display: block;
			
			a{
				display: block;
				padding: 5px 10px;
				color: #000;
				text-align: center;
			}
		}
	}
}

ul.language_menu > li:hover > ul.language_submenu{
	display: block;
}
ul.language_menu > li > a:hover, ul.language_submenu > li > a:hover{
	color: orange;
}