.wrapper_content{
	//background: url('../img/content_bg.jpg') repeat-x;
	//background-position: 100% 100%;
	background: linear-gradient(to bottom, #fff, #F3F3F3);
	@include clearfix;
}

.content{
	@include width1000;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #fff;
	border: 1px solid #EDEDED;
	box-shadow: rgba(221,221,221,1) 0px 0px 4px;
	@include clearfix;
	
	@include respond-to(handhelds){
		width: 100%;
	}
	@include respond-to(wide-handhelds){
		width: 100%;
	}
	@include respond-to(small-tablets){
		width: 100%;
	}
	@include respond-to(tablets){
		width: 100%;
	}
}

//text-block
.text-block{
	@include common-text-default;
	
	.title-text-block{
		text-align: center;
		padding: 10px 0;
		background: url('../img/new_activity_title_bg.jpg') repeat-x;
		background-size: contain;
		background-position: left bottom;
		margin-bottom: 15px;
		
		p{
			text-align: center;
			font-size: 13px;
		}
	}
	
	.image-block{
		float: left;
		width: 30%;
		padding: 5px 5px 5px 0;
		
		&-right{
			float: right;
			width: 30%;
			padding: 5px 0 5px 5px;
		}
		&-center{
			margin: 0 auto;
			text-align: center;
		}
	
	}

	.title-block{
		padding: 10px 0;
		margin: 0 20px;
		border-bottom: 1px solid #E5E5E5;
		
		h2{
			padding: 0;
			margin: 0;
		}
	}
}

.baner{
	@include width1000;
	padding: 5px 0;
	line-height: 0;
	
	img{
		width: 100%;
		margin: 0;
		padding: 0;
	}
}