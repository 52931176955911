.left-block{
	width: 20%;
	float: left;
	box-sizing: border-box;
	
	.title-left{
		padding: 10px;
		background: linear-gradient(to bottom, #99A2A9, #6C7680);
		border-radius: 5px 5px 0 0;
		
		h3{
			padding: 0;
			margin: 0;
		}
	}
	ul{
		li{
			background: linear-gradient(to bottom, #F00202, #D70010);
			
			a{
				padding: 12px 20px;
				display: block;
				font-weight: 600;
				font-size: 12px;
				color: #fff;
			}
		}
	}
	
	@include respond-to(small-tablets){
		float: none;
		width: 100%;
	}	
	@include respond-to(wide-handhelds){
		float: none;
		width: 100%;
	}	
	@include respond-to(handhelds){
		float: none;
		width: 100%;
	}
}


.right-block{
	width: 78%;
	float: right;
	padding: 20px;
	box-sizing: border-box;
	background-color:#fff;
	box-shadow: rgba(221,221,221,1) 0px 0px 10px;
	
	@include respond-to(small-tablets){
		float: none;
		width: 100%;
	}	
	@include respond-to(wide-handhelds){
		float: none;
		width: 100%;
		padding: 10px;
	}	
	@include respond-to(handhelds){
		float: none;
		width: 100%;
		padding: 10px;
	}
}


.news-list-block{
	font-size: 0;
	@include clearfix;
	
	li{
		position: relative;
		@include clearfix;
		padding: 10px 0;
		margin-bottom: 15px;
		border-bottom: 1px solid #E2E2E2;

		.left-img-cell{
			display: block;
			float: left;
			width: calc(30% - 20px);
			margin: 10px;
			padding: 4px;
			line-height: 100%;
			border: 1px solid #E2E2E2;
			box-sizing: border-box;
			overflow: hidden;
			
			img{
				width: 100%;
				padding: 0;
				margin: 0;
			}
			
			@include respond-to(handhelds){
				float: none;
				width: 90%;
				margin: 0 auto;
			}
		}
		
		a:hover img{
			transform: scale(1.02);
			transition-duration: 0.5s;
		}
		
		.text-block-left{
			float: left;
			width: 70%;
			padding: 0 10px;
			box-sizing: border-box;
		
			h3{
				float: left;
				font-size: 18px;
				margin-bottom: 5px;
				
				a{
					color: #000;
				}
				
				a:hover{
					color: brown;
					transition: 0.5s;
					text-decoration: none;
				}
				
				@include respond-to(handhelds){
					float: none;
				}
			}
			p{
				font-size: 11px;
				line-height: 130%;
				clear: both;
				text-align: left;
				color: #aaa;
				margin-bottom: 5px;
				
				span{
					color: #000;
				}
				
				a{
					color: #aaa;
					font-size: 13px;
					
					&:hover{
						text-decoration: none;
					}
				}
				
				@include respond-to(handhelds){
					text-align: justify;
				}
			}
			p:hover span{
				color: brown;
			}
			
			@include respond-to(handhelds){
				width: 100%;
				padding: 0;
			}
		}
		
		&:hover{
				background: url('../img/content_bg.jpg') repeat-x;
				background-size: contain;
		}
		
		&:last-child{
			border-bottom: none;
		}
	}
}
.news-list-block:after{
	content: '';
	display: table;
	clear: both;
}