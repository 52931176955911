/*news-slider*/
.wrapper-mini-slider{
	background-color: #E6E7E7;
	
	.mini-slider{
		padding: 10px 0;
		margin: 0 auto;
		@include width1000;
		background-color: #E6E7E7;


		
		@include respond-to(display){
			width: 95%;
		}
		@include respond-to(tablets){
			width: 90%;
		}
		@include respond-to(small-tablets){
			width: 90%;
		}
	}
}

//navigation
.owl-prev{
	top: 30%;
	left: -20px;
	color: #fff;
	font-size: 40px;
	background: url('../img/home_bot_btn.jpg');

	@include respond-to(display){
		top: 35%;
	}
	@include respond-to(wide-handhelds){
		left: 20px;
	}
	@include respond-to(handhelds){
		left: 20px;
	}
}
.owl-next{
	top: 30%;
	right: -20px;
	color: #fff;
	font-size: 40px;
	
	@include respond-to(display){
		top: 35%;
	}
	@include respond-to(wide-handhelds){
		right: 20px;
	}
	@include respond-to(handhelds){
		right: 20px;
	}
}

//item
.item{
	margin: 5px 10px;
	box-shadow: rgba(156,156,156,0.9) 0px 0px 3px;
	background-color: #F1F1F1;
}
.news{
	background: linear-gradient(to top, #E8E8E8, #fff);
	background-color:red;
}
.img-niw-block{
	padding: 4px;
}
.img-niw-block img{
	width: 100%;
}
 .news p{
	font-size: 12px;
	padding: 5px;
	color: #000;
}
.item p span{
	color: red;
}


//gallery index, view
.gallery-slider{
	width: 95%;
	margin: 10px auto;
}
.gallery-slider2{
	width: 100%;
	margin: 10px auto;
}
.gallery-slider2 img{
	width: 100%;
}
.gallery-slider-main{
	width: 100%;
	margin: 0 auto;
	background:#e6e7e7;

	.slider-for-main{
	max-width: 1200px;
	margin: 0 auto;

	}

}
.gallery-slider-main img{
	width: 100%;
}
.slider-nav2, .slider-nav-main{
	display: none;
}
.slider-nav{
	padding: 0 80px;
	background: linear-gradient(to bottom, #fff, #D0D0D0);
	
	@include respond-to(handhelds){
		padding: 0;
	}
	@include respond-to(wide-handhelds){
		padding: 0;
	}
}
.slider-nav img{
	margin: 10px;
	padding: 4px;
	border: 1px solid #aaa;
	
	@include respond-to(handhelds){
		margin: 0;
	}
	@include respond-to(wide-handhelds){
		margin: 0;
	}
}