.path-block{
	@include width1000;
	
	@include respond-to(handhelds){
		width: 100%;
	}
	@include respond-to(wide-handhelds){
		width: 100%;
	}
	@include respond-to(small-tablets){
		width: 100%;
	}
	@include respond-to(tablets){
		width: 100%;
	}
}
.path{
	padding-left: 10px;
	
	li{
		display: inline-block;
		padding: 12px 3px;
		
		a{
			font-size: 11px;
			color: #828282;
			
			&:hover{
				color: #aaa;
			}
		}
	}
	
	.place{
		color: #c3010e;
		font-size: 11px;
	}
}