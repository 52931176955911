//list of auto
.unit_car{
	@include clearfix;
	background-color: #fff;
	margin: 20px 40px;
	padding: 30px;
	border: 1px solid #DFDFDF;
	background: url('../img/selContBg.jpg');
	background-position: 100% 100%;
	background-size: cover;
	
	img{
		float: left;
		max-width: 400px;
		
		@include respond-to(small-tablets){
			width: 100%;
			max-width: 300px;
		}	
		@include respond-to(wide-handhelds){
			float: none;
			width: 100%;
			max-width: 450px;
		}	
		@include respond-to(handhelds){
			float: none;
			width: 100%;
			max-width: 650px;
		}
	}
	
	.title_unit{
		float: left;
		padding-top: 20px;
		padding-left: 50px;

		h1{
			@include respond-to(small-tablets){
				font-size: 20px;
			}
			@include respond-to(wide-handhelds){
				font-size: 20px;
			}
			@include respond-to(handhelds){
				font-size: 20px;
			}
		}
		ul{
			padding: 20px 0;
			
			li{
				position: relative;
				margin: 4px 0;
				border-radius: 15px;
				border: 1px solid #aaa;
				width: 150px;
				
				a{
					background-color: #F0EFEF;
					padding: 5px 10px;
					margin: 1px;
					display: block;
					border-radius: 15px;

					color: #4F4F4F;
					
					&:hover{
						background-color:#E8E8E8;
					}
					
					span{
						border-radius: 15px;
						background-color: #D6000F;
						position: absolute;
						padding: 5px 7px 5px 9px;
						top: 1px;
						right: 2px;
						color: #fff;
					}
				}
				
				@include respond-to(handhelds){
					text-align: left;
				}
				@include respond-to(wide-handhelds){
					text-align: left;
				}
			}
			
			@include respond-to(wide-handhelds){
				padding: 10px 0;
			}
			@include respond-to(small-tablets){
				padding: 10px 0;
			}
		}

		@include respond-to(handhelds){
			float: none;
			padding-left: 0;
		}
		@include respond-to(wide-handhelds){
			float: none;
			padding-left: 0;
		}
		@include respond-to(small-tablets){
			padding-left: 20px;
		}	
	}
	
	h2{
		color: #CD2626;
	}

	
	@include respond-to(tablets){
		padding: 10px;
		margin: 20px;
	}
	@include respond-to(small-tablets){
		padding: 10px;
		margin: 20px;
	}		
	@include respond-to(wide-handhelds){
		padding: 10px;
		margin: 20px;
		text-align: center;
	}	
	@include respond-to(handhelds){
		padding: 10px;
		margin: 10px;
		text-align: center;
	}
}