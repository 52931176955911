//all cars

.wrapper-all-auto {

background: #f5f5f5;

.main-auto {max-width: 1200px; margin:0px auto;}

}



.card-grid-sale{
	@include clearfix;
	font-size: 0;
	padding: 10px 0 20px 0;
	margin: 0;

	li{
		display: inline-block;
		vertical-align:top;
		margin: 10px;
		@include clearfix;
		position: relative;
		border: 1px solid #EDEDED;
		background: url('../img/card.png');
		background-size: cover;
		
		.img-niw-block img{
			width: calc(100% - 22px);
			margin: 10px;
		}
		
		.title_unit{
			width: 100%;
			font-size: 13px;
			padding: 10px 0;
			margin: 0;
			text-align: center;

		& > a {
			color: #c3010e;
			font-size: 16px;
			font-weight: bold;
			padding: 5px 10px;
			margin: 1px;
			display: block;
		}	
		
		& > p > a {
			color: #c3010e;
			font-size: 16px;
			font-weight: bold;
			padding: 5px 10px;
			margin: 1px;
			display: block;
		}

		}

		.title_unit ul{
			padding: 5px 0;
		}
		.title_unit ul li{
			position: relative;
			margin: 4px 0;
			border-radius: 15px;
			border: 1px solid #aaa;
			width: 140px;
		}
		.title_unit h2{
			color: #CD2626;
		}
		.title_unit ul li a{
			color: #4F4F4F;
			background-color: #F0EFEF;
			padding: 5px 10px;
			margin: 1px;
			display: block;
			border-radius: 15px;
			text-align: left;
		}

		.title_unit ul li a span{
			border-radius: 15px;
			background-color: #D6000F;
			position: absolute;
			padding: 5px 7px 5px 9px;
			top: 1px;
			right: 2px;
			color: #fff;
		}
		.title_unit a:hover{
			background-color:#E8E8E8;
		}
		
		
		@include respond-to(display-big){
			width:calc(25% - 22px);
		}
		@include respond-to(display){
			width:calc(25% - 22px);
		}
		@include respond-to(tablets){
			width:calc(25% - 22px);
		}
		@include respond-to(small-tablets ){
			width:calc(33.33% - 12px);
			margin: 5px;
		}
		@include respond-to(wide-handhelds){
			width:calc(50% - 12px);
			margin: 5px;
		}
		@include respond-to(handhelds){
			width:calc(100% - 12px);
			margin: 5px;
		}
	}
	li:hover .text-card a{
		color: #fff;
		border: none;
	}
}